import request from '@/utils/request'


// 查询短剧宣传列表
export function listPublicize(query) {
  return request({
    url: '/video/videoPublicize/list',
    method: 'get',
    params: query
  })
}

// 查询短剧宣传分页
export function pagePublicize(query) {
  return request({
    url: '/video/videoPublicize/page',
    method: 'get',
    params: query
  })
}

// 查询短剧宣传详细
export function getPublicize(data) {
  return request({
    url: '/video/videoPublicize/detail',
    method: 'get',
    params: data
  })
}

// 新增短剧宣传
export function addPublicize(data) {
  return request({
    url: '/video/videoPublicize/add',
    method: 'post',
    data: data
  })
}

// 修改短剧宣传
export function updatePublicize(data) {
  return request({
    url: '/video/videoPublicize/edit',
    method: 'post',
    data: data
  })
}

// 删除短剧宣传
export function delPublicize(data) {
  return request({
    url: '/video/videoPublicize/delete',
    method: 'post',
    data: data
  })
}

export function getGatherList(query) {
  return request({
    url: '/video/video-gathers/getGatherList',
    method: 'get',
    params: query
  })
}
