<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="短剧名称" prop="videoId" >
        <!--        <a-input v-model="form.videoId" placeholder="请输入短剧id" />-->
        <a-select
            v-model="form.videoId"
            showSearch
            style="width: 100%"
            placeholder="请选择短剧"
            allow-clear
            @change="getGatherList"
            optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.videoName" v-for="(item,index) in listAll">
            {{item.videoName}}
          </a-select-option>
        </a-select>
      </a-form-model-item>


      <a-form-model-item label="剧集" prop="gatherId" >
        <!--        <a-input v-model="form.videoId" placeholder="请输入短剧id" />-->
        <a-select
          v-model="form.gatherId"
          showSearch
          style="width: 100%"
          placeholder="请选择剧集"
          allow-clear
          optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.serialNumber" v-for="(item,index) in gatherList">
            第{{item.serialNumber}}集
          </a-select-option>
        </a-select>
      </a-form-model-item>



      <a-form-model-item label="视频" prop="videoUrl" >
        <!--        <a-input v-model="form.videoUrl" placeholder="请输入短剧url" />-->
        <a-upload
            listType="picture-card"
            class="avatar-uploader"
            v-model="form.videoUrl"
            action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"
            :showUploadList="false"
            :beforeUpload="beforeUploadVideo"
            :customRequest="handleChange"
        >
          <video :src="form.videoUrl" v-if="form.videoUrl" width="300" height="150" controls>

          </video>
          <div v-if="form.videoUrl" class="ant-upload-text">更换图片</div>

          <!--          <img v-if="form.videoUrl" :src="form.videoUrl" alt="logo"-->
          <!--               style="height:104px;max-width:300px"/>-->
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>

            <div class="ant-upload-text">上传</div>

            <a-progress v-if="percent>0" :percent="percent" />
          </div>
          <!--          <a-spin v-if="uploadLoading" />-->
        </a-upload>
      </a-form-model-item>



<!--      <a-form-model-item label="中文字幕" prop="subtitleCn">-->
<!--        &lt;!&ndash;           action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"&ndash;&gt;-->

<!--        &lt;!&ndash;        <iframe v-if="this.form.subtitleCn" :src="this.form.subtitleCn" width="600" height="300"></iframe>&ndash;&gt;-->
<!--        <a-upload-->
<!--          action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"-->
<!--          v-model="this.form.subtitleCn"-->
<!--          :show-upload-list="false"-->
<!--          :customRequest="handleChangeCn"-->
<!--        >-->

<!--          <a-button type="primary" v-if="this.form.subtitleCn">更换</a-button>-->
<!--          <a-button v-else> <a-icon type="upload" /> 上传{{uploadLoadings ? '中':''}} </a-button>-->
<!--        </a-upload>-->

<!--        <div style="margin-top: 10px;margin-bottom: 10px">-->


<!--        </div>-->
<!--      </a-form-model-item>-->
<!--      &lt;!&ndash;      action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"&ndash;&gt;-->
<!--      <a-form-model-item label="英文字幕" prop="subtitleEn">-->
<!--        &lt;!&ndash;        {{fileListEn}}&ndash;&gt;-->
<!--        <a-upload-->
<!--          action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"-->
<!--          :showUploadList="false"-->
<!--          :customRequest="handleChangeEn"-->
<!--        >-->
<!--          <a-button type="primary" v-if="form.subtitleEn">更换</a-button>-->
<!--          <a-button v-else> <a-icon type="upload" /> 上传{{uploadLoadings ? '中':''}} </a-button>-->
<!--        </a-upload>-->
<!--      </a-form-model-item>-->



      <a-form-model-item label="排序" prop="seq" >
        <a-input v-model="form.seq" placeholder="请输入排序" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPublicize, addPublicize, updatePublicize,getGatherList } from '@/api/video/publicize'
import {listVideo} from "@/api/video/video";
import UploadFileToVOD from "@/api/vod";
import axios from "axios";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      listAll:[],
      gatherList:[],
      submitLoading: false,
      uploadLoading: false,
      uploadLoadings: false,
      percent: 0,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        videoId: null,
        gatherId: null,

        videoUrl: null,
        subtitleEn: null,
        subtitleCn: null,

        seq: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        videoId: [
          { required: true, message: '短剧id不能为空' }
        ],
        gatherId: [
          { required: true, message: '剧集不能为空' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getGather();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    handleChangeEn(info) {
      let that = this;
      let FormDatas = new FormData();
      FormDatas.append('file', info.file)
      axios({
        method: 'post',
        url: "https://web.frameflash.com/admin-api/common/local/file/uploadCos", // 接口地址
        data: FormDatas,
        // 请求配置按需配置即可
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data;boundary=' + new Date().getTime()
        },
      }).then((res) => {

        that.form.subtitleEn = res.data.data.mediaUrl;//文件名
        that.$message.success("操作成功")
      })
      // if (info.file.status === 'done') {
      //   console.log(info.file.response.data.mediaUrl);
      //   this.form.subtitleEn = info.file.response.data.mediaUrl;
      //   // Get this url from response in real world.
      //   // getBase64(info.file.originFileObj, imageUrl => {
      //   //   this.imageUrl = imageUrl;
      //   //   this.uploadLoading = false;
      //   // });
      // } else {
      //   this.form.subtitleEn = "";
      // }
      return;
      if(info.file.status === 'removed') {
        this.fileListEn = []
      } else {
        let fileList = [...info.fileList];
        fileList[0].name = '文件'
        fileList = fileList.slice(-1);
        fileList = fileList.map(file => {
          if (file.response) {
            file.url = file.response.url;
          }
          return file;
        });
        this.fileListEn = fileList;
        if (info.file.status === 'done') {
          console.log(info.file.response.data.mediaUrl);
          this.form.subtitleEn = info.file.response.data.mediaUrl;
          // Get this url from response in real world.
          // getBase64(info.file.originFileObj, imageUrl => {
          //   this.imageUrl = imageUrl;
          //   this.uploadLoading = false;
          // });
        } else {
          this.form.subtitleEn = "";
        }
      }


    },
    beforeUploadVideo:function (file) {
      var fileType = file.type;
      if (fileType.indexOf('video') < 0) {
        this.$message.warning('请上传视频');
        this.form.videoUrl = '';
        return false
      }

      //TODO 驗證文件大小
    },

    getGatherList(value) {
      getGatherList({"id": value}).then(res => {
        this.gatherList = res.data;
      })
    },

    handleChangeCn(info) {

      let that = this;
      let FormDatas = new FormData();
      FormDatas.append('file', info.file)
      axios({
        method: 'post',
        url: "https://web.frameflash.com/admin-api/common/local/file/uploadCos", // 接口地址
        data: FormDatas,
        // 请求配置按需配置即可
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data;boundary=' + new Date().getTime()
        },
      }).then((res) => {
        that.form.subtitleCn = res.data.data.mediaUrl;//文件名
        that.$message.success("操作成功")
      })




    },

    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true;
        return;
      }
      let that = this
      that.form.videoUrl = ""
      new UploadFileToVOD([info.file], {
        success: function (fileName) {

          that.form.videoUrl = fileName[0];//文件名
          that.uploadLoading = false;
        },
        vProgress:function (val) {
          console.log(val)
          that.percent=val*100

        }
      });
      that.uploadLoading = false;
      this.percent=0


    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    getGather() {
      listVideo().then(res => {
        this.listAll = res.data;
      })
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        videoId: null,
        videoUrl: null,
        seq: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.form = {
        id: null,

            videoId: null,

            videoUrl: null,

            seq: null,

            createTime: null,

            remark: null,

      }
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPublicize({"id":id}).then(response => {
        this.form = response.data
        this.getGatherList(this.form.videoId)
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePublicize(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addPublicize(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
